<template>
  <b-container class='my-4'>
    <b-card-group deck>
        <b-card header="Login" header-tag="h1" header-class="h4" class="mx-auto" style="max-width: 540px;">
          <b-card-text>

            <b-alert v-model="isError" variant="danger">There was a problem validating your information.</b-alert>

            <b-form @submit.prevent="submit_form">

              <template v-if="!isLoading">
                <b-row class='form-group-row'>
                  <div class="col-12">
                    <b-form-group
                      id="username-group"
                      label="Username:"
                      label-for="username"
                      class="text-left"
                      >
                      <b-form-input
                        id="username"
                        v-model="form.username"
                        type="text"
                        placeholder="Username"
                        required
                        />
                    </b-form-group>
                  </div>

                  <div class="col-12">
                    <b-form-group
                      id="password-group"
                      label="Password:"
                      label-for="password"
                      class="text-left"
                      >
                      <b-form-input
                        id="password"
                        v-model="form.password"
                        type="password"
                        placeholder="Password"
                        required
                        />
                    </b-form-group>
                  </div>

                </b-row>
                <b-button type="submit" variant="primary">Login</b-button>
              </template>
              
              <div v-if="isLoading" class="text-center text-danger my-2">
                <b-spinner small></b-spinner>
                <strong class="ml-2">Validating Login Information...</strong>
              </div>
            </b-form>

          </b-card-text>
      </b-card>
    </b-card-group>
  </b-container>
</template>
<script>
// import axios from 'axios'
import { createNamespacedHelpers } from 'vuex';
const { mapActions } = createNamespacedHelpers( 'auth' );
export default {
  data(){
    return {
      form: {
        username: '',
        password: ''
      },
      isError: false,
      isLoading: false
    }
  },
  methods: {

    async submit_form() {
      this.isError = false;
      this.isLoading = true;
      const result = await this.login( this.form );
      if ( result.success ) {
        const { redirect = false } = this.$route.query;
        const path = redirect ? decodeURI(redirect) : '/';
        this.$router.push({ path });
      } else {
        this.isError = true;
        this.isLoading = false;
      }
    },

    ...mapActions([
      'login'
    ]),

  }
}
</script>


<style lang='scss' scoped>
button[type=submit] {
  margin-top: 1.5rem;
  display: block;
  width: auto;
  margin-left: auto;
}
</style>